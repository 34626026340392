/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { RevTeams } from '../data/rev-teams';
import { REVTeamBox } from './rev-team-box';

interface IProps {
  id: number;
  mode: string;
}

export const REVTeamEntry: React.FC<IProps> = ({ id, mode }) => {
  const team = RevTeams.find((team) => team.ID === id);

  return <>{mode === 'icon' && <REVTeamBox team={team} />}</>;
};
