import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './teams.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Col, Form, Row } from 'react-bootstrap';
import lodash from 'lodash';
import Select from 'react-select';
import { RevTeams } from '../../modules/reverse/common/data/rev-teams';
import { REVTeamEntry } from '../../modules/reverse/common/components/rev-team';

const RevTeamDB: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [currentNiche1, setCurrentNiche1] = useState('All');
  const [currentNiche2, setCurrentNiche2] = useState('All');
  const [visibleTeams, setVisibleTeams] = useState(RevTeams);
  const [textSearch, setTextSearch] = useState('');

  const sortChangeHandler = (e) => {
    setCurrentNiche1(e.value);
  };

  const modeChangeHandler = (e) => {
    setCurrentNiche2(e.value);
  };

  const nicheOptions = [
    { value: 'All', label: 'All Tags' },
    { value: 'Bellows', label: 'Bellows' },
    { value: 'Budget', label: 'Budget' },
    { value: 'Bullet', label: 'Bullet' },
    { value: 'Burn', label: 'Burn' },
    { value: 'Control', label: 'Control' },
    { value: 'Crit', label: 'Crit' },
    { value: 'Debuff', label: 'Debuff' },
    { value: 'Defensive', label: 'Defensive' },
    { value: 'Eureka', label: 'Eureka' },
    { value: 'FUA', label: 'FUA' },
    { value: 'Gold', label: 'Gold' },
    { value: 'Gorgon', label: 'Gorgon' },
    { value: 'Healing', label: 'Healing' },
    { value: 'HP Loss', label: 'HP Loss' },
    { value: 'Hypothesis', label: 'Hypothesis' },
    { value: 'Inspiration', label: 'Inspiration' },
    { value: 'Isle', label: 'Isle' },
    { value: 'Marsh', label: 'Marsh' },
    { value: 'Nightmare', label: 'Nightmare' },
    { value: 'Opera', label: 'Opera' },
    { value: 'Poison', label: 'Poison' },
    { value: 'Raw DMG', label: 'Raw DMG' },
    { value: 'Reality', label: 'Reality' },
    { value: 'Set Up', label: 'Set Up' },
    { value: 'Shift', label: 'Shift' },
    { value: 'Support Core', label: 'Support Core' },
    { value: 'Upgrade', label: 'Upgrade' }
  ];

  const defaultNicheOption = currentNiche1
    ? nicheOptions.find((opt) => opt.value === currentNiche1)
    : null;

  const defaultNicheOption2 = currentNiche2
    ? nicheOptions.find((opt) => opt.value === currentNiche2)
    : null;

  useEffect(() => {
    let filtered = RevTeams;

    filtered = filtered.sort((a, b) => (b.ID < a.ID ? 1 : -1));

    setVisibleTeams(filtered);
    forceUpdate();
  }, []);

  useEffect(() => {
    let filtered = RevTeams;

    if (currentNiche1 != 'All') {
      filtered = filtered.filter((team) => {
        if (team.Niche_1 === currentNiche1) return true;
        if (team.Niche_2 === currentNiche1) return true;
      });
    }

    if (currentNiche2 != 'All') {
      filtered = filtered.filter((team) => {
        if (team.Niche_1 === currentNiche2) return true;
        if (team.Niche_2 === currentNiche2) return true;
      });
    }

    if (textSearch != '') {
      filtered = filtered.filter(
        (team) =>
          team.Carry.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
          team.Member_2.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
          team.Member_3.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
          team.Member_4.toLowerCase().indexOf(textSearch.toLowerCase()) > -1
      );
      filtered = lodash.uniqBy(filtered, 'ID');
    }

    filtered = filtered.sort((a, b) => (b.ID < a.ID ? 1 : -1));

    setVisibleTeams(filtered);
    forceUpdate();
  }, [currentNiche1, currentNiche2, textSearch]);

  const items = visibleTeams.map((weapon, idx) => {
    return (
      <div key={idx}>
        <REVTeamEntry id={weapon.ID} mode="icon" />
      </div>
    );
  });

  return (
    <DashboardLayout className={'generic-page rev-teams-page '} game="reverse">
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>Teams Database</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/reverse/categories/category_teams.jpg"
            alt="Teams Database"
          />
        </div>
        <div className="page-details">
          <h1>Reverse 1999 Team Database</h1>
          <h2>The best teams you can use in Reverse 1999 in various modes.</h2>
          <p>
            Last updated: <strong>13/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader
        title="Reverse 1999 Teams"
        subtitle={
          <span className="additional-text">
            Showing {visibleTeams.length} team
            {visibleTeams.length !== 1 && 's'}
          </span>
        }
      />
      <Row xs={1} xl={3} xxl={3} className="filter">
        <Col>
          <Form.Control
            size="lg"
            type="text"
            placeholder="Search for characters"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
          />
        </Col>
        <Col>
          <Select
            options={nicheOptions}
            className="custom-dropdown order-2 order-md-2"
            isSearchable={true}
            onChange={sortChangeHandler}
            defaultValue={defaultNicheOption}
            classNamePrefix="custom-dropdown-inner"
          />
        </Col>
        <Col>
          <Select
            options={nicheOptions}
            className="custom-dropdown order-2 order-md-2"
            isSearchable={true}
            onChange={modeChangeHandler}
            defaultValue={defaultNicheOption2}
            classNamePrefix="custom-dropdown-inner"
          />
        </Col>
      </Row>
      <div className="rev-teams-container">{items}</div>
      {items.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../images/reverse/no_results.webp"
            alt="No results"
          />
          <p>No teams found. Try changing your filter.</p>
        </div>
      )}
      <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
    </DashboardLayout>
  );
};

export default RevTeamDB;

export const Head: React.FC = () => (
  <Seo
    title="Teams Database | Reverse: 1999 | Prydwen Institute"
    description="The best teams you can use in Reverse: 1999 in various modes."
    game="reverse"
  />
);
