export const RevTeams = [
  {
    ID: 1,
    Name: 'Powercreep Where',
    Carry: 'Windsong',
    Member_2: 'Flutterpage',
    Member_3: 'Lopera',
    Member_4: 'Vila',
    Niche_1: 'FUA',
    Niche_2: 'Crit',
    Explanation:
      "Flutterpage allows Windsong to go from 10-turn cycles to mere 3-turn cycles, utilizing the generated FUAs to upkeep FP's Forcefield buff and thus massively increase Windsong's damage done. The short cycle also allows Lopera's buff stacks to be of note."
  },
  {
    ID: 2,
    Name: 'Toxic Therapists',
    Carry: 'Willow',
    Member_2: 'Tuesday',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Poison',
    Niche_2: 'Crit',
    Explanation:
      "Willow and Tuesday provide an obviously powerful core in allowing [Hag's Bane] to Crit. In addition, the Genesis DMG Taken debuff allows Kakania to deal more damage, who can AFK her output and DMG Reduction while Vila can passively heal and only needs 1 AP every so often for her buff."
  },
  {
    ID: 3,
    Name: 'Imagine Playing The Game',
    Carry: 'Lucy',
    Member_2: 'Kakania',
    Member_3: 'Mercuria',
    Member_4: 'Vila',
    Niche_1: 'Raw DMG',
    Niche_2: 'FUA',
    Explanation:
      "Probably the most AFK team one can come up with. Cast Lucy cards, kill mobs, make sure Mercuria's [Cosmic Energy] stays stacked (which is easy so long as you're killing stuff) and you'll be doing very consistently high damage while taking very little in return."
  },
  {
    ID: 4,
    Name: 'The Orphanage (Not Sorry)',
    Carry: '37',
    Member_2: 'anjo-nala',
    Member_3: 'Flutterpage',
    Member_4: 'Fatutu',
    Niche_1: 'FUA',
    Niche_2: 'Crit',
    Explanation:
      "Contracted: Fatutu. Through Fatutu and Anjo Nala, Flutterpage gains enough stacks of [Gust] per round to maximize 37's FUA damage under Fatutu's debuffs. Doing it this way allows 37 to deal competitive damage."
  },
  {
    ID: 5,
    Name: "Girl's Best Friend",
    Carry: 'jiu-niangzi',
    Member_2: 'Pickles',
    Member_3: 'Flutterpage',
    Member_4: 'Fatutu',
    Niche_1: 'FUA',
    Niche_2: 'Crit',
    Explanation:
      "Using Pickles' Euphoria, Jiu Niangzi sees a large increase in damage and also the option to add Flutterpage to her team. Then, further enhanced by Fatutu's debuffs, she can maximize the damage of her FUAs despite the randomness of [Liquor]."
  },
  {
    ID: 6,
    Name: 'Marcus And Her Parents',
    Carry: 'Marcus',
    Member_2: 'Mercuria',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Upgrade',
    Niche_2: 'Raw DMG',
    Explanation:
      'Crit included but kind of secondary, the primary idea here is to use a feedback loop between Marcus casting high rank Incantations and Mercuria generating passive stacks, which results in more [Adjustment] for Marcus etc. Vila and Kakania are here as the strongest sustain core.'
  },
  {
    ID: 7,
    Name: 'Carpet Bombing',
    Carry: 'Lilya',
    Member_2: 'anjo-nala',
    Member_3: 'Flutterpage',
    Member_4: 'Fatutu',
    Niche_1: 'FUA',
    Niche_2: 'Crit',
    Explanation:
      "Contracted: Lilya. As Anjo Nala's Ultimate turns the [Contract]ed Ult into a FUA, combined with Lilya's constant stacking of Ultimate Might buffs with her Euphoria allows it to benefit from Flutterpage and Fatutu (de)buffs."
  },
  {
    ID: 8,
    Name: 'Pew Pew Pew',
    Carry: 'Barcarola',
    Member_2: 'Voyager',
    Member_3: 'Matilda',
    Member_4: 'tooth-fairy',
    Niche_1: 'Inspiration',
    Niche_2: 'Raw DMG',
    Explanation:
      'A strong "Impromptu Incantation" Support comp focused around maximizing its damage output and its hit frequency. Due to Matilda (and Voyager, both with Euphoria) being present, it is also easier to build for newer players. Tooth Fairy is here because she is more AP-friendly than Vila.'
  },
  {
    ID: 9,
    Name: "Guns 'n Roses",
    Carry: 'Lucy',
    Member_2: 'Isolde',
    Member_3: 'Lopera',
    Member_4: 'Kakania',
    Niche_1: 'Crit',
    Niche_2: 'Burn',
    Explanation:
      "A bit more of a risky composition abusing the fact that Lopera can turn Lucy's [Ammunition] into a permanent effect with her Array and optimizing the C.DMG gained from her buffs by adding Isolde for the additional [Burn]. Risky due to relying on Lopera+Kakania for healing."
  },
  {
    ID: 10,
    Name: 'Lucy Budget',
    Carry: 'Lucy',
    Member_2: 'Bkornblume',
    Member_3: 'Lorelei',
    Member_4: 'tooth-fairy',
    Niche_1: 'Crit',
    Niche_2: 'Budget',
    Explanation:
      'A budget team for Lucy. Bkornblume and Lorelei between them provide a slew of good support effects from -Reality DEF and +DMG Taken to +Crit DMG, all of whom Lucy can benefit from given her huge Crit Tech.'
  },
  {
    ID: 11,
    Name: 'Underpaid Professors',
    Carry: 'Windsong',
    Member_2: '6',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Set Up',
    Niche_2: 'Crit',
    Explanation:
      'Typical set-up team, though more geared towards long cycles. For short cycles, shift 6 out for someone like Mercuria or a Portraited 37. This team specifically is designed to keep Windsong safe while she and 6 set-up a large hand for her to Ult off of.'
  },
  {
    ID: 12,
    Name: 'Heatseeking Missle',
    Carry: 'Windsong',
    Member_2: 'Argus',
    Member_3: 'Mercuria',
    Member_4: 'Vila',
    Niche_1: 'Set Up',
    Niche_2: 'Crit',
    Explanation:
      "Argus allows characters that can't typically benefit from Mercuria enjoy her presence, and on shorter cycles Windsong can do a lot to provide her with stacks of [Pinpointed]. Besides that, Argus fills up the holes in DPT while WS sets up and can stack Mercuria at the same time using [Grave Digger]."
  },
  {
    ID: 13,
    Name: 'Necrologist To Bury These MFs',
    Carry: 'Windsong',
    Member_2: 'Barbara',
    Member_3: 'Necrologist',
    Member_4: 'Vila',
    Niche_1: 'Set Up',
    Niche_2: 'Budget',
    Explanation:
      "Budget team with a hybrid cycle. There's enough survivability to make it through long cycles but provided that Barbara can generate cards and Vila has access to +Moxie it can be used for short cycles also (though you'd likely swap Necrologist out for something quicker)."
  },
  {
    ID: 14,
    Name: 'Benefit From Reading',
    Carry: 'Marcus',
    Member_2: '6',
    Member_3: 'Barbara',
    Member_4: 'Yenisei',
    Niche_1: 'Set Up',
    Niche_2: 'Budget',
    Explanation:
      'Somewhat a budget version. 6 helps with Incantation upgrades via [Empowerment] while Barabara can generate extra incantations for Marcus while providing an additional defensive anchor on top of Yenisei (who can also upgrade her own Incantations).'
  },
  {
    ID: 15,
    Name: 'One Punch Woman',
    Carry: 'Spathodea',
    Member_2: 'Isolde',
    Member_3: 'Getian',
    Member_4: 'tooth-fairy',
    Niche_1: 'Crit',
    Niche_2: 'Burn',
    Explanation:
      "Primary Phys/Burn team centered around coupling Crit to [Burn] to maximize Spathodea's damage output. Getian under [Preignition] can apply a lot of [Burn] to make Spath's Ultimate, TF is there to soak stacks of [Preignition] in case Spath doesn't have any stacks to hit on."
  },
  {
    ID: 16,
    Name: 'Canonically On Fire',
    Carry: 'Spathodea',
    Member_2: 'Ulu',
    Member_3: 'Bkornblume',
    Member_4: 'tooth-fairy',
    Niche_1: 'Burn',
    Niche_2: 'Budget',
    Explanation:
      'Terrifyingly budget, this team is moreso focused on applying [Burn] and other debuffs and leaves the Crit entirely to Spathodea, which she can do, but its harder to cycle.'
  },
  {
    ID: 17,
    Name: 'Imagine Drunk Isolde...',
    Carry: 'jiu-niangzi',
    Member_2: 'Isolde',
    Member_3: 'Kakania',
    Member_4: 'tooth-fairy',
    Niche_1: 'FUA',
    Niche_2: 'Crit',
    Explanation:
      "Pretty standard comp for those who can utilize Isolde. This team is focused on dealing high continuous damage through JNZ and Isolde, with Kakania cycling her Genesis DMG in the background. TF is here to provide as healer who can also trigger [Preignition] which Vila can't ."
  },
  {
    ID: 18,
    Name: 'Jiu Niangzi Reading Classes',
    Carry: 'jiu-niangzi',
    Member_2: '6',
    Member_3: 'Necrologist',
    Member_4: 'Yenisei',
    Niche_1: 'Raw DMG',
    Niche_2: 'Budget',
    Explanation:
      'A bit more focused on the defensive side compared to normal teams, 6 is used to consistently provide JNZ with enough [Liquor] while Necrologist offers the highest possible DMG Dealt buff generally. Yenisei is here as the GOAT among low-star healers.'
  },
  {
    ID: 19,
    Name: 'Toxic Lesbians',
    Carry: 'Jessica',
    Member_2: 'Tuesday',
    Member_3: 'Isolde',
    Member_4: 'tooth-fairy',
    Niche_1: 'Poison',
    Niche_2: 'Crit',
    Explanation:
      "Yes, technically Tuesday provides more [Poison] than Jessica needs, but this also means that Jessica has both BoN and her [Poison] DMG Bonus maxed out at all times, Isolde at that point is just there for the DEF shred and additional [Poison] application via [Fear's Cradle]."
  },
  {
    ID: 20,
    Name: 'Fae Forest',
    Carry: 'Jessica',
    Member_2: 'Isolde',
    Member_3: 'Getian',
    Member_4: 'tooth-fairy',
    Niche_1: 'Debuff',
    Niche_2: 'Raw DMG',
    Explanation:
      "The true BoN set-up for Jessica. Isolde and Getian's combined Reality DEF shred accompanied by Getian's massive Array buff and also the ability to proc the psychube through [Burn] + [Shape of Bones] greatly helps mitigate the reliance on [Poison]."
  },
  {
    ID: 21,
    Name: 'Jessica Tries To Poison',
    Carry: 'Jessica',
    Member_2: 'Tuesday',
    Member_3: 'Bkornblume',
    Member_4: 'Sotheby',
    Niche_1: 'Poison',
    Niche_2: 'Budget',
    Explanation:
      "A Non-Poison team for Jessica, focused on triggering BoN + [Poison] DMG Bonus while also providing as much additional support as possible. Who's a better option than Bkornblume? Her -Reality DEF and +DMG Taken debuffs are great and her own DMG output is too!"
  },
  {
    ID: 22,
    Name: 'Dogwalking',
    Carry: 'Kaalaa-Baunaa',
    Member_2: 'Pickles',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Raw DMG',
    Niche_2: 'Crit',
    Explanation:
      "Kaalaa Baunaa is still among the most AP-Greedy of Carries so allowing her space is imperative. Pickles is among the best for this, and Kakania can be left alone during the fight as well. In addition, Vila's +Moxie and massive Crit buffs are basically perfect for KB."
  },
  {
    ID: 23,
    Name: 'Stargazing',
    Carry: 'Kaalaa-Baunaa',
    Member_2: 'Barbara',
    Member_3: 'Necrologist',
    Member_4: 'Vila',
    Niche_1: 'Raw DMG',
    Niche_2: 'Budget',
    Explanation:
      "If AP-positive teammates aren't available, you might as well force the game. Barbara is great at this, having an AP-friendly kit and being able to generate cards for allies. In a Raid setting, Vila's +Moxie should make up for Necro's spam and her Crit buffs are amazing for KB."
  },
  {
    ID: 24,
    Name: 'Wandering Teachers',
    Carry: 'Melania',
    Member_2: 'Mercuria',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Crit',
    Niche_2: 'Set Up',
    Explanation:
      "Melania spams her skills enough to make Mercuria work and in turn Mercuria helps alleviate Melania's main damage problems (her Incantations) with her massive DMG Bonus, Incan Might and CDMG. Kakania and Vila here are a standard sustain core."
  },
  {
    ID: 25,
    Name: 'Will Clock You',
    Carry: 'Melania',
    Member_2: 'Necrologist',
    Member_3: 'Kakania',
    Member_4: 'Yenisei',
    Niche_1: 'Raw DMG',
    Niche_2: 'Budget',
    Explanation:
      "Melania generally can be played AP-neutral enough to pair with Necro for the massive DMG Bonus. In addition, Kakania's [Solace] ATK buff is particularly powerful in the hands of our master thief and Yenisei's [Flow] upgrading can help when Tuning for Melania cards."
  },
  {
    ID: 26,
    Name: 'SPEEEEN',
    Carry: '37',
    Member_2: 'Isolde',
    Member_3: 'jiu-niangzi',
    Member_4: 'tooth-fairy',
    Niche_1: 'FUA',
    Niche_2: 'Eureka',
    Explanation:
      "This composition focuses on providing 37 with as many FUAs as possible to continuously trigger [Supplementary Formula]. Isolde doensn't have a perfect synergy with 37, but the Reality DEF shred is used by JNZ anyway."
  },
  {
    ID: 27,
    Name: "SPEEEEN (Taylor's Version)",
    Carry: '37',
    Member_2: 'Lorelei',
    Member_3: 'Lilya',
    Member_4: 'Yenisei',
    Niche_1: 'Crit',
    Niche_2: 'Budget',
    Explanation: ''
  },
  {
    ID: 28,
    Name: 'HP Loss Without The Loss',
    Carry: 'Semmelweis',
    Member_2: 'Pickles',
    Member_3: 'Eternity',
    Member_4: 'Fatutu',
    Niche_1: 'HP Loss',
    Niche_2: 'FUA',
    Explanation:
      "A very strong \"HP Loss\" team making full use of Pickles' [Rousing Morale] stacks and FUA boost. Fatutu might be a confusing choice, however, her damage share actually counts as 'self damage' and stacks Semmelweis' Array even if it keeps her at full HP. Can also use NewBabel over Fatutu."
  },
  {
    ID: 29,
    Name: 'Lesbians + A Knight',
    Carry: 'A-Knight',
    Member_2: 'Isolde',
    Member_3: 'Argus',
    Member_4: 'tooth-fairy',
    Niche_1: 'Crit',
    Niche_2: '',
    Explanation:
      "A Knight is a very middle-of-the-road Carry and benefits greatly from Isolde's buffs, debuffs and her damage support. Argus is the same, benefitting from the team's frequent attacks to stack [Pinpointed] while dealing a fair bit of her own damage."
  },
  {
    ID: 30,
    Name: 'Budgetknights',
    Carry: 'A-Knight',
    Member_2: 'Bkornblume',
    Member_3: 'Argus',
    Member_4: 'Yenisei',
    Niche_1: 'Raw DMG',
    Niche_2: 'Budget',
    Explanation:
      'When out of options, Bkornblume remains an extremely powerful Reality debuffer, which can significantly boost both A Knight and Argus in power. Meanwhile, while the team is fairly tanky to begin with due to A Knight and Bkornblume, Yenisei further enhances this.'
  },
  {
    ID: 31,
    Name: 'Predictive Measures',
    Carry: 'Centurion',
    Member_2: 'Isolde',
    Member_3: 'Getian',
    Member_4: 'tooth-fairy',
    Niche_1: 'Crit',
    Niche_2: '',
    Explanation:
      'Centurion is one of many Reality Carries that link extremely well with the strong Isolde + Getian core, as it provides a stacked Reality DEF shred, continuous buffing (also stacked) and high personal damage from both Supports.'
  },
  {
    ID: 32,
    Name: 'Centurion and her Fans',
    Carry: 'Centurion',
    Member_2: 'Bkornblume',
    Member_3: 'Barbara',
    Member_4: 'tooth-fairy',
    Niche_1: 'Raw DMG',
    Niche_2: 'Budget',
    Explanation:
      'A bit more of a defensive composition surrounding Barbara being able to generate cards for her allies, allowing Centurion and Bkornblume to cycle their Ultimates more quickly and output increasingly more damage.'
  },
  {
    ID: 33,
    Name: 'Ye Olde Vampires',
    Carry: 'Charlie',
    Member_2: 'Semmelweis',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Raw DMG',
    Niche_2: 'HP Loss',
    Explanation:
      "Charlie (assumedly P3+) requires a certain HP level for her Incantation 2 to deal additional damage, which is supported by Semmelweis actively allowing her to lower other's HP. Kakania and Vila provide passive sustain, making sure Charlie doesn't drop TOO low."
  },
  {
    ID: 34,
    Name: 'Copium',
    Carry: 'Charlie',
    Member_2: 'Semmelweis',
    Member_3: 'Kakania',
    Member_4: 'Yenisei',
    Niche_1: 'HP Loss',
    Niche_2: 'Budget',
    Explanation:
      "Semmelweis can artificially lower Charlie's HP, helping with hitting her Incantation requirements. Yenisei can target-heal around Charlie and keep her alive with Shields in the meantime, and Kakania provides a bit of passive sustain."
  },
  {
    ID: 35,
    Name: 'Rigor Mortis',
    Carry: 'druvis-iii',
    Member_2: 'an-an-lee',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Raw DMG',
    Niche_2: 'Control',
    Explanation:
      'Druvis and An-An Lee might seem like an odd team-up, but with both having access to single-target CC, they work around eachother in a CC oriented team, with Kakania dealing passive damage to the entire enemy team and Vila providing sustain.'
  },
  {
    ID: 36,
    Name: "Girls Don't Take Sh*t",
    Carry: 'druvis-iii',
    Member_2: 'Satsuki',
    Member_3: 'Kakania',
    Member_4: 'Yenisei',
    Niche_1: 'Control',
    Niche_2: 'Budget',
    Explanation:
      "The typical Petrify team also still works, using Satsuki to extend Druvis' debuff and occasionally inflict it on multiple enemies at once. Here, Kakania and Yenisei provide an unbreakable defense coupled with Druvis' [Circle of Life]."
  },
  {
    ID: 37,
    Name: 'Tank Loocy',
    Carry: 'Lucy',
    Member_2: 'Getian',
    Member_3: 'ezra-theodore',
    Member_4: 'Vila',
    Niche_1: 'Eureka',
    Niche_2: 'Crit',
    Explanation:
      "Ramping based [Advancement] team focused on gaining Moxie and Eureka to charge Lucy's upgrades extremely quickly."
  },
  {
    ID: 38,
    Name: 'Isolde Self-Insert',
    Carry: 'Lucy',
    Member_2: 'Isolde',
    Member_3: 'Getian',
    Member_4: 'Vila',
    Niche_1: 'Debuff',
    Niche_2: 'Crit',
    Explanation:
      "Debuff based Lucy team focusing on the Isolde+Getian core to shred large amounts of Reality DEF and optimize Lucy's output through debuffs."
  },
  {
    ID: 39,
    Name: 'Suicide Squad',
    Carry: 'Windsong',
    Member_2: 'Semmelweis',
    Member_3: 'Mercuria',
    Member_4: 'Vila',
    Niche_1: 'Set Up',
    Niche_2: 'HP Loss',
    Explanation:
      "Highly risky HP Drain Hypercarry set-up for Windsong focused on absolutely maximizing Windsong's damage output at the cost of any proper defensive core."
  },
  {
    ID: 40,
    Name: "Let's Go Lesbians Let's Go",
    Carry: 'Windsong',
    Member_2: 'Mercuria',
    Member_3: 'Argus',
    Member_4: 'Vila',
    Niche_1: 'Crit',
    Niche_2: 'Upgrade',
    Explanation:
      "Crit based Windsong composition that forgoes Kakania's safety to play in on the Argus+Mercuria dynamic as a way of filling the time between Windsong rotations."
  },
  {
    ID: 41,
    Name: 'Women and Daughters',
    Carry: 'Windsong',
    Member_2: 'Lorelei',
    Member_3: 'Mercuria',
    Member_4: 'Vila',
    Niche_1: 'Crit',
    Niche_2: 'Set Up',
    Explanation:
      "Experimental composition focused around Lorelei's Ultimate having infinite scaling with the amount of cards in your hand (in this case, Attack)."
  },
  {
    ID: 42,
    Name: 'PLANT',
    Carry: 'Marcus',
    Member_2: 'druvis-iii',
    Member_3: 'an-an-lee',
    Member_4: 'Vila',
    Niche_1: 'Control',
    Niche_2: 'Upgrade',
    Explanation:
      'Control based Marcus team focused on combining raw damage output with elements of [Control] to ease out stages with annoying hazards like buffer enemies.'
  },
  {
    ID: 43,
    Name: 'Vila in Reading Class',
    Carry: 'Marcus',
    Member_2: 'Mercuria',
    Member_3: 'Argus',
    Member_4: 'Vila',
    Niche_1: 'Upgrade',
    Niche_2: 'Crit',
    Explanation:
      'Experimental BiS core for Marcus in Raids, due to generally not being great in Raids; the Argus+Mercuria core excels at it, utilizing [Cosmic Energy] and [Pinpointed] ramping to get Marcus up to speed.'
  },
  {
    ID: 44,
    Name: 'Spirits of Burn',
    Carry: 'Spathodea',
    Member_2: 'Isolde',
    Member_3: 'J',
    Member_4: 'tooth-fairy',
    Niche_1: 'Burn',
    Niche_2: '',
    Explanation:
      "Full [Burn] based team focused on applying as much [Burn] as possible (thus also guaranteeing the Crit DMG trigger on Spathodea's Ultimate."
  },
  {
    ID: 45,
    Name: 'The Great Heist',
    Carry: 'Melania',
    Member_2: 'Leilani',
    Member_3: 'darley-clatter',
    Member_4: 'Vila',
    Niche_1: 'Raw DMG',
    Niche_2: '',
    Explanation:
      "Gimmick composition focused on ramping Melania's Ultimate as quickly as possible."
  },
  {
    ID: 46,
    Name: 'Fly Squirrel Fly',
    Carry: 'Melania',
    Member_2: 'Argus',
    Member_3: 'ezra-theodore',
    Member_4: 'Vila',
    Niche_1: 'Crit',
    Niche_2: '',
    Explanation:
      "Crit based Melania composition utilziing Ezra's natural synergy (+Argus/Vila de/buffs) with her in a 4-man setting to optimize both Melania's Ultimate as well as Incantation damage."
  },
  {
    ID: 47,
    Name: "Windsong Support Can't Hurt You",
    Carry: '37',
    Member_2: 'Windsong',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Set Up',
    Niche_2: 'Gorgon',
    Explanation:
      'Balanced team revolving around quick Windsong cycles to trigger [Supplementary Formula] often while having a balanced defensive core with Kakania and Vila.'
  },
  {
    ID: 48,
    Name: '37 (Burn Version)',
    Carry: 'J',
    Member_2: '37',
    Member_3: 'Isolde',
    Member_4: 'tooth-fairy',
    Niche_1: 'FUA',
    Niche_2: 'Crit',
    Explanation:
      'Adapted FUA team with a separate niche in [Burn] application through J and Isolde.'
  },
  {
    ID: 49,
    Name: 'Ouija Board',
    Carry: 'A-Knight',
    Member_2: 'Isolde',
    Member_3: 'Mercuria',
    Member_4: 'Barbara',
    Niche_1: 'Upgrade',
    Niche_2: 'Crit',
    Explanation:
      'Spirit-Only Team (Not to be taken to seriously, but does function if played well).'
  },
  {
    ID: 50,
    Name: 'Suicide Squad',
    Carry: 'J',
    Member_2: 'Kakania',
    Member_3: 'Semmelweis',
    Member_4: 'ms-newbabel',
    Niche_1: 'HP Loss',
    Niche_2: 'Crit',
    Explanation:
      'Considering the Euphoria for Ms Newbabel, she combos well with J which helps avoid the problem of running too much healing in a HP Drain team.'
  },
  {
    ID: 51,
    Name: "I'm A Healer But...",
    Carry: 'Kakania',
    Member_2: 'tooth-fairy',
    Member_3: 'Vila',
    Member_4: 'Yenisei',
    Niche_1: 'Healing',
    Niche_2: 'Gorgon',
    Explanation:
      "Healer-only team that through constantly triggering Kakania's Genesis DMG can do extremely well in long fights such as Gorgon."
  },
  {
    ID: 52,
    Name: 'Geneva Suggestion',
    Carry: 'J',
    Member_2: 'Isolde',
    Member_3: 'Tuesday',
    Member_4: 'Sotheby',
    Niche_1: 'Poison',
    Niche_2: 'Burn',
    Explanation:
      'The "True DoT" team combining large amounts of [Burn] and [Poison] application to overwhelm enemies with debuffs and make a lot of funny numbers appear on screen.'
  },
  {
    ID: 53,
    Name: 'WHY ARE YOU RUNNING',
    Carry: 'druvis-iii',
    Member_2: 'an-an-lee',
    Member_3: 'Satsuki',
    Member_4: 'Voyager',
    Niche_1: 'Control',
    Niche_2: '',
    Explanation:
      'Crowd Control team utilizing Druvis and Satsuki to continually inflict [Petrify] while AAL supports with [Daze] and Voyager can prevent further actions with [Refrain].'
  },
  {
    ID: 54,
    Name: 'They Would Have A Gun',
    Carry: 'Horropedia',
    Member_2: 'Argus',
    Member_3: 'Mercuria',
    Member_4: 'Vila',
    Niche_1: 'Control',
    Niche_2: 'Crit',
    Explanation:
      "Dispeller team centering around Horropedia's high damage output against unbuffed targets by utilizing Argus to help dispel and debuff and Mercuria to offer passive buffs."
  },
  {
    ID: 55,
    Name: 'SET SAIL',
    Carry: 'White-Rum',
    Member_2: '37',
    Member_3: 'J',
    Member_4: 'Vila',
    Niche_1: 'FUA',
    Niche_2: 'Shift',
    Explanation:
      "A funny Shift-based team combining Rum and J's consistent FUAs and decent Mass DMG to supplement 37's kit."
  },
  {
    ID: 56,
    Name: 'Book Bomb',
    Carry: 'Marcus',
    Member_2: 'anjo-nala',
    Member_3: 'Mercuria',
    Member_4: 'Vila',
    Niche_1: 'Raw DMG',
    Niche_2: '',
    Explanation:
      "Contracted: Marcus. Utilizes Marcus' quick Moxie generation to supplement Anjo Nala's quick cycles combined with Mercuria to provide all the needed buffing."
  },
  {
    ID: 57,
    Name: 'Single Target Lucy',
    Carry: 'Lucy',
    Member_2: 'anjo-nala',
    Member_3: 'Argus',
    Member_4: 'Vila',
    Niche_1: 'Raw DMG',
    Niche_2: 'FUA',
    Explanation:
      "Contracted: Lucy. Mostly a Raid team utilizing Lucy's AP-greediness to spam ST FUAs with Anjo Nala, supplementing Argus' [Pinpointed] debuff and ST DMG spam."
  },
  {
    ID: 58,
    Name: 'Deja Vu',
    Carry: 'Melania',
    Member_2: 'Argus',
    Member_3: 'anjo-nala',
    Member_4: 'Vila',
    Niche_1: 'Raw DMG',
    Niche_2: '',
    Explanation:
      "Contracted: Melania. Utilizes Melania's spammy kit to supplement Anjo Nala's Moxie generation with the Beast Contract which both cycles Melania and the [Pinpointed] debuff."
  },
  {
    ID: 59,
    Name: 'Sotheby Learns Karate',
    Carry: 'Sotheby',
    Member_2: 'Tuesday',
    Member_3: 'anjo-nala',
    Member_4: 'tooth-fairy',
    Niche_1: 'Poison',
    Niche_2: 'Crit',
    Explanation:
      "Contracted: Sotheby. Feeds off of Sotheby's card spam to quickly cycle Anjo Nala's Ultimate, exploiting the extra damage she does on debuffs and creating a [Poison] CC team."
  },
  {
    ID: 60,
    Name: 'Balanced, as all things should be',
    Carry: 'Lucy',
    Member_2: '37',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Gorgon',
    Niche_2: 'Crit',
    Explanation:
      '37 team centered around dealing with Gorgon adds and dealing damage to the boss at the same time.'
  },
  {
    ID: 61,
    Name: 'Poison x 3',
    Carry: 'J',
    Member_2: 'Tuesday',
    Member_3: 'Sotheby',
    Member_4: 'tooth-fairy',
    Niche_1: 'Gorgon',
    Niche_2: 'Debuff',
    Explanation:
      'Team focused on [Poison] application while keeping enough personal sustain to make it past the later stages of the fight.'
  },
  {
    ID: 62,
    Name: 'Green',
    Carry: 'Kakania',
    Member_2: '',
    Member_3: '',
    Member_4: '',
    Niche_1: 'Gorgon',
    Niche_2: 'Healing',
    Explanation:
      'Meme team for Portrait 5 Kakania but she can technically solo SSS Gorgon.'
  },
  {
    ID: 63,
    Name: 'Drunk Marsh',
    Carry: 'jiu-niangzi',
    Member_2: 'Semmelweis',
    Member_3: '6',
    Member_4: 'Balloon-Party',
    Niche_1: 'Marsh',
    Niche_2: 'HP Loss',
    Explanation:
      "JNZ's constant attacks help easily trigger Semmelweis' HP Drain and the defensive core consists primarily of healing that progressively gets more value the lower HP they are."
  },
  {
    ID: 64,
    Name: 'This Is Actually Good Everywhere',
    Carry: 'Lucy',
    Member_2: 'Mercuria',
    Member_3: 'Getian',
    Member_4: 'Vila',
    Niche_1: 'Marsh',
    Niche_2: 'Crit',
    Explanation:
      'Team focused around Lucy+Mercuria to upkeep high damage percentiles with Getian reducing Reality DEF and triggering his Array frequently through VIla.'
  },
  {
    ID: 65,
    Name: 'Ultimate Gold',
    Carry: 'Melania',
    Member_2: 'Argus',
    Member_3: '6',
    Member_4: 'Vila',
    Niche_1: 'Gold',
    Niche_2: 'Crit',
    Explanation:
      'Team focused on spamming Ultimates frequently through Melania and Argus in combination with casting a lot of attacks to help stack [Pinpointed] quickly.'
  },
  {
    ID: 66,
    Name: 'Reality DEF Malding',
    Carry: 'Lucy',
    Member_2: 'Bkornblume',
    Member_3: 'Getian',
    Member_4: 'Vila',
    Niche_1: 'Gold',
    Niche_2: 'Debuff',
    Explanation:
      "Debuff team focused on keeping Gold's DMG output low. Doens't spam Ultimates as much, but Lucy synergizes with the stage hazard of increasing DMG Dealt right after casting Ult."
  },
  {
    ID: 67,
    Name: 'Car Bomb',
    Carry: 'Sotheby',
    Member_2: 'Tuesday',
    Member_3: '6',
    Member_4: 'Vila',
    Niche_1: 'Gold',
    Niche_2: 'Poison',
    Explanation:
      "Poison team centered around keeping Gold's DMG output low while also providing a decent access to Ultimates and high damage per round."
  },
  {
    ID: 68,
    Name: 'Mechanical Defect',
    Carry: 'Spathodea',
    Member_2: 'J',
    Member_3: 'Isolde',
    Member_4: 'Necrologist',
    Niche_1: 'Hypothesis',
    Niche_2: 'Burn',
    Explanation:
      'Burn oriented Burst team featuring a ludicrous amount of [Burn] while also having a lot of attacks to deal with the smaller ads and keep the boss in his [Exposed] state.'
  },
  {
    ID: 69,
    Name: 'Cable Erosion',
    Carry: 'Jessica',
    Member_2: 'Argus',
    Member_3: 'Tuesday',
    Member_4: 'tooth-fairy',
    Niche_1: 'Hypothesis',
    Niche_2: 'Poison',
    Explanation:
      'Focuses on both [Poison] and burst, using Argus to help deal with the Futurism enemy while Tuesday and Tooth Fairy ensure the enemy team is constantly locked in debuffs.'
  },
  {
    ID: 70,
    Name: 'Also Actually Good Everywhere',
    Carry: 'Lucy',
    Member_2: 'Isolde',
    Member_3: 'Kakania',
    Member_4: 'tooth-fairy',
    Niche_1: 'Hypothesis',
    Niche_2: 'Crit',
    Explanation:
      'Crit-oriented team with a bit of [Burn] application and Mass DMG on the side. All-around use team that can fit all of the mechanics in Hypothesis like upkeeping DoTs and hitting Futurism.'
  },
  {
    ID: 71,
    Name: 'Spin Me Right Round...',
    Carry: 'Lucy',
    Member_2: '37',
    Member_3: 'Isolde',
    Member_4: 'Vila',
    Niche_1: 'Nightmare',
    Niche_2: 'Crit',
    Explanation:
      'FUA based team focused on applying lots of Mass DMG continuously. Does need some time to get online i.e. when Lucy upgrades her FUA and Isolde hits [Interlude].'
  },
  {
    ID: 72,
    Name: 'TiD on Isolde is viable I swear',
    Carry: 'A-Knight',
    Member_2: 'Isolde',
    Member_3: 'Getian',
    Member_4: 'Kakania',
    Niche_1: 'Nightmare',
    Niche_2: 'Raw DMG',
    Explanation:
      'Focused on spamming Mass attacks, an "official" healer isn\'t necessary here as the amount of damage hitting the enemy team will avoid most problems until SS.'
  },
  {
    ID: 73,
    Name: 'Does Your 37 Is Have Portraits',
    Carry: '37',
    Member_2: 'jiu-niangzi',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Nightmare',
    Niche_2: 'FUA',
    Explanation:
      'Different FUA based team with a bit more defensive coverage in Kakania. Not as optimal at handling multiple enemies as others, but can hit SSS just fine.'
  },
  {
    ID: 74,
    Name: 'Marcus & Co Do Isle Master',
    Carry: 'Marcus',
    Member_2: 'Mercuria',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Isle',
    Niche_2: 'Raw DMG',
    Explanation:
      "The primary team to deal with Isle Master, Marcus frequent upgrading in addition with Mercuria's powerful passive buffs help with stacking [Thunderous] quickly."
  },
  {
    ID: 75,
    Name: 'Upgrades People Upgrades!',
    Carry: 'Lucy',
    Member_2: 'Argus',
    Member_3: 'Mercuria',
    Member_4: 'Vila',
    Niche_1: 'Isle',
    Niche_2: 'Upgrade',
    Explanation:
      'Lucy team focused on keeping Isle Master in [Pinpointed] while the consistent FUAs in combination with Mercuria help stack [Thunderous].'
  },
  {
    ID: 76,
    Name: 'Several Punches !!!',
    Carry: 'Spathodea',
    Member_2: 'Isolde',
    Member_3: 'Getian',
    Member_4: 'Vila',
    Niche_1: 'Isle',
    Niche_2: 'Crit',
    Explanation:
      "Alternative team that doesn't really fit the mechanics of the fight, however, constant debuffs and high damage output allow it to SSS anyway."
  },
  {
    ID: 77,
    Name: 'Windsong When 30 Rounds',
    Carry: 'Windsong',
    Member_2: '6',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Opera',
    Niche_2: 'Crit',
    Explanation:
      "The main team for dealing with Opera. With Kakania on the side, you can long-cycle to line up her Ultimate with Windsong's as the passive healing output is quite considerable."
  },
  {
    ID: 78,
    Name: 'Lucy Because',
    Carry: 'Lucy',
    Member_2: 'Ezra-Theodore',
    Member_3: 'Isolde',
    Member_4: 'Vila',
    Niche_1: 'Opera',
    Niche_2: 'Raw DMG',
    Explanation:
      'Helps with consistently high Crit DMG while keeping semi-protective with Ezra and Vila as Isolde is quite squishy especially later in the fight.'
  },
  {
    ID: 79,
    Name: 'Does The BNW Exploit Still Work?',
    Carry: 'Kaalaa-Baunaa',
    Member_2: '6',
    Member_3: 'Ezra-Theodore',
    Member_4: 'Vila',
    Niche_1: 'Opera',
    Niche_2: 'Crit',
    Explanation:
      "An alternative team using Kaalaa Baunaa to line up with Ezra's mushrooms while keeping the team safe with a solid defensive core. Can also be used to exploit BNW."
  },
  {
    ID: 80,
    Name: 'BELLO',
    Carry: 'Lucy',
    Member_2: '37',
    Member_3: 'Argus',
    Member_4: 'J',
    Niche_1: 'Bellows',
    Niche_2: 'FUA',
    Explanation:
      "Since the ads delete themselves they trigger Lucy's FUA, resulting in a snowball effect that cycles Lucy and 37 FUAs, which helps stack [Pinpointed] while J covers the defensive."
  },
  {
    ID: 81,
    Name: 'Gun Squad (Mercuria WOULD)',
    Carry: 'Lucy',
    Member_2: 'Argus',
    Member_3: 'Mercuria',
    Member_4: 'Kakania',
    Niche_1: 'Bellows',
    Niche_2: 'Upgrade',
    Explanation:
      'Different Lucy team that utilizes the Argus+Mercuria support core to hyperbuff Lucy and consistently let her trigger FUAs, meaning that Kakania is the only required sustain.'
  },
  {
    ID: 82,
    Name: 'No Vila (Crying)',
    Carry: 'Willow',
    Member_2: 'Tuesday',
    Member_3: 'J',
    Member_4: 'ms-newbabel',
    Niche_1: 'Poison',
    Niche_2: 'Crit',
    Explanation:
      'A Willow team centered around allowing Willow to take as much AP as possible by relegating sustain and debuff application to J and NewBabel with her Euphoria.'
  },
  {
    ID: 83,
    Name: 'Isoldephobia',
    Carry: 'Willow',
    Member_2: 'Tuesday',
    Member_3: 'anjo-nala',
    Member_4: 'Vila',
    Niche_1: 'Poison',
    Niche_2: 'Crit',
    Explanation:
      "Contracted: Tuesday. Utilizes Willow and Tuesday as AP-hoarders to spam Anjo Nala's attacks and crowd control. The combined ST damage, debuff application and damage over time enhances the output considerably."
  },
  {
    ID: 84,
    Name: 'Suicide Squad 2',
    Carry: 'J',
    Member_2: 'Semmelweis',
    Member_3: 'Eternity',
    Member_4: 'ms-newbabel',
    Niche_1: 'HP Loss',
    Niche_2: 'Crit',
    Explanation:
      "Primary HP Loss focused team utilizing J's higher damage at lower HP values and Semmelweis + Eternity Euphoria to quickly lower his HP to minimum values while keeping the team safe with NewBabel's Euphoria."
  },
  {
    ID: 85,
    Name: 'This Works Stop Being Mean',
    Carry: 'Shamane',
    Member_2: 'Flutterpage',
    Member_3: 'anjo-nala',
    Member_4: 'Lopera',
    Niche_1: 'Crit',
    Niche_2: 'Raw DMG',
    Explanation:
      "Contracted: Shamane. Exploits the targeting on Shamane's Moxie generation to quickly cycle Anjo Nala's Ultimate while consistently spamming both FUAs. Generally kept alive with Lopera's healing but is a very risky team."
  },
  {
    ID: 86,
    Name: 'Lilya On FIAA',
    Carry: 'Isolde',
    Member_2: 'Lopera',
    Member_3: 'Lilya',
    Member_4: 'Vila',
    Niche_1: 'Bullet',
    Niche_2: 'Burn',
    Explanation:
      "Niche team utilizing Lilya's second Euphoria to create a proper [Bullet] team. Isolde is there to further buff Crit DMG and provide [Burn] to optimize Lopera's own Crit buffing."
  },
  {
    ID: 87,
    Name: 'FLY HIGH (pls get the reference)',
    Carry: 'Lilya',
    Member_2: 'Flutterpage',
    Member_3: '37',
    Member_4: 'ms-newbabel',
    Niche_1: 'Crit',
    Niche_2: 'FUA',
    Explanation:
      'Orients itself around FUA spam through Lilya and Ms. NewBabel with Euphoria to charge both Flutterpage and 37 at the same time.'
  },
  {
    ID: 88,
    Name: 'Eeping On The Clock',
    Carry: '37',
    Member_2: 'anjo-nala',
    Member_3: 'Flutterpage',
    Member_4: 'Vila',
    Niche_1: 'Crit',
    Niche_2: 'Control',
    Explanation:
      'Contracted: 37. Team oriented around the Star [Contract] allowing 37 and Anjo Nala to perma CC the opposing team through [Captivated] while Flutterpage exists to reliable enhance FUA damage.'
  },
  {
    ID: 89,
    Name: 'The Bunny Approaches',
    Carry: 'Blonney',
    Member_2: 'Argus',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'Crit',
    Niche_2: '',
    Explanation:
      "Blonney requires allies that can allow her to take a lot of actions. To that end, Argus and Kakania form a solid offensive and defensive AP-friendly support Core while Vila increases the power of Blonney's Incantations, which are her strong suit, and can also grant her Moxie."
  },
  {
    ID: 90,
    Name: 'Budget Bunny',
    Carry: 'Blonney',
    Member_2: 'Argus',
    Member_3: 'Lorelei',
    Member_4: 'Yenisei',
    Niche_1: 'Crit',
    Niche_2: 'Budget',
    Explanation:
      'Blonney, being a 5-star, likes defensive support on top of AP-friendly allies. Argus fills the latter role, only having to act every few turns. On the other hand, Lorelei provides a good Crit DMG and DMG Taken Reduced buff while Yenisei anchors that with healing and shields.'
  },
  {
    ID: 91,
    Name: 'Budget Bunny 2',
    Carry: 'Blonney',
    Member_2: 'Lorelei',
    Member_3: '6',
    Member_4: 'Vila',
    Niche_1: 'Crit',
    Niche_2: 'Budget',
    Explanation:
      "A more budget oriented team for Blonney, combos 6's access to [Incantation Empowerment] and [Song of Passion] from Vila to enhance Blonney's power providing Moxie for Blonney. Lorelei is there as both offensive and defensive buffs."
  },
  {
    ID: 92,
    Name: 'Vampire Survivors',
    Carry: 'Eternity',
    Member_2: 'Semmelweis',
    Member_3: '6',
    Member_4: 'Kakania',
    Niche_1: 'HP Loss',
    Niche_2: 'Raw DMG',
    Explanation:
      "Eternity best bet due to her own self-healing is someone like Semmelweis, who has powerful buffs at the cost of draining your team's HP. Add 6 for consistent buffing and a character like Kakania to provide any additional healing and you have a core vampire team."
  },
  {
    ID: 93,
    Name: 'Benefit From Scamming',
    Carry: 'Eternity',
    Member_2: 'Semmelweis',
    Member_3: 'Tennant',
    Member_4: 'Yenisei',
    Niche_1: 'HP Loss',
    Niche_2: 'Budget',
    Explanation:
      "Eternity best bet due to her own self-healing is someone like Semmelweis, who has powerful buffs at the cost of draining your team's HP. Meanwhile, Tennant's shields and DMG Reduction combined with Yenisei's targeted healing help keep the team safe despite the HP Drain."
  },
  {
    ID: 94,
    Name: 'Lesbians and War Criminals',
    Carry: 'Lilya',
    Member_2: 'Isolde',
    Member_3: 'Kakania',
    Member_4: 'tooth-fairy',
    Niche_1: 'Crit',
    Niche_2: '',
    Explanation:
      'Lilya really wants two things: extra ATK, extra Crit DMG. She easily hits near maximum Crit Rate by herself, but has low base ATK and no Crit Conversion. In comes Isolde with a massive Crit DEF debuff on top of Reality DEF shred and Kakania, who can apply a strong ATK buff.'
  },
  {
    ID: 95,
    Name: 'Budget Fighter Jet',
    Carry: 'Lilya',
    Member_2: 'Lorelei',
    Member_3: 'Bkornblume',
    Member_4: 'tooth-fairy',
    Niche_1: 'Crit',
    Niche_2: 'Budget',
    Explanation:
      "Realistically, Lilya wants Crit DMG and ATK. This team won't provide both, but Bkornblume's Reality DEF shred and DMG Taken debuff provide a similar effect, coupled with Lorelei's Crit DMG and DMG Dealt buff,"
  },
  {
    ID: 96,
    Name: 'Singing Songwriters',
    Carry: 'Sweetheart',
    Member_2: 'Isolde',
    Member_3: 'Kakania',
    Member_4: 'tooth-fairy',
    Niche_1: 'Debuff',
    Niche_2: 'Raw DMG',
    Explanation:
      "Sweetheart, similarly to Jessica, is a debuff based Carry. Due to this, Isolde is perfect: she provides Mass debuffs and consistently applies [Burn] to enemies; paired with Tooth Fairy, the debuffs on this team don't run out. All of this is backed by the usual Kakania pick."
  },
  {
    ID: 97,
    Name: 'Chanel No. 5',
    Carry: 'Sweetheart',
    Member_2: 'Bkornblume',
    Member_3: 'Necrologist',
    Member_4: 'tooth-fairy',
    Niche_1: 'Debuff',
    Niche_2: 'Budget',
    Explanation:
      "When characters like Isolde aren't available, fret not, there are plenty of 5-stars like Bkornblume who can do the job. Necrologist is also present for her DMG Dealt and [Prayer] buffs. Tooth Fairy's passive debuff application should make sure enemies are never without,"
  },
  {
    ID: 98,
    Name: 'Too Much Support For A 4-star',
    Carry: 'Eagle',
    Member_2: 'Isolde',
    Member_3: 'Argus',
    Member_4: 'tooth-fairy',
    Niche_1: 'Crit',
    Niche_2: '',
    Explanation:
      'Isolde+Argus core setup provides anywhere between a 50-95% Crit DEF shred plus extra buffs from both of them. Tooth Fairy is there for the debuffs since Eagle mostly cares about her Ultimate, not her Incantations.'
  },
  {
    ID: 99,
    Name: 'Quiet And Dangerous',
    Carry: 'Eagle',
    Member_2: 'Bkornblume',
    Member_3: 'Lorelei',
    Member_4: 'tooth-fairy',
    Niche_1: 'Crit',
    Niche_2: 'Budget',
    Explanation:
      'A little less focused on single-target damage and Crit, Bkornblume and Lorelei can both be used as additional carries while providing Reality DEF shred, DMG Reduction shred, Crit DMG and DMG Dealt, all in a 5-star support package.'
  },
  {
    ID: 100,
    Name: 'This Was Written Pre 2.4',
    Carry: 'Matilda',
    Member_2: 'Argus',
    Member_3: 'Mercuria',
    Member_4: 'Vila',
    Niche_1: 'Crit',
    Niche_2: 'Set Up',
    Explanation:
      'Mercuria and Vila here provide easy access to large amounts of Crit Rate, allowing Matilda to focus less on her debuff Incantation and actually perform her job as Carry. In addition, due to being single-target focused, Argus is a solid secondary Carry with her powerful debuff.'
  },
  {
    ID: 101,
    Name: 'Foreseeing Dogsh*t',
    Carry: 'Matilda',
    Member_2: 'Lorelei',
    Member_3: 'Barbara',
    Member_4: 'Vila',
    Niche_1: 'Crit',
    Niche_2: 'Budget',
    Explanation:
      "Matilda has less access to Crit Rate in this team, so Barbara bringing in additional cards for her while providing a secondary defensive anchor greatly improves the survivability of the team and Lorelei's offensive buffs help keep the pace on attacks."
  },
  {
    ID: 102,
    Name: 'Suicide Squad (Actually)',
    Carry: 'Pavia',
    Member_2: 'Semmelweis',
    Member_3: 'Kakania',
    Member_4: 'Vila',
    Niche_1: 'HP Loss',
    Niche_2: 'Crit',
    Explanation:
      "Pavia is similar to Eternity and Charlie, but also the only one who benefits from Semmelweis' HP draining, as it lets him bounce between 50% of his HP to trigger his skill requirements. Add to that Kakania to make sure he survives and Vila for her Incantation DMG increase."
  },
  {
    ID: 103,
    Name: "My Dog Don't Bite!",
    Carry: 'Pavia',
    Member_2: 'Semmelweis',
    Member_3: 'Kakania',
    Member_4: 'Yenisei',
    Niche_1: 'HP Loss',
    Niche_2: 'Budget',
    Explanation:
      'Semmelweis allows Pavia to ping-pong between his health requirements, letting him maximize his damage output while Kakania provides DMG reduction with [Empathy] and Yenisei can heal around him while providing shields.'
  },
  {
    ID: 104,
    Name: 'Hunting For Screentime (PLS)',
    Carry: 'Mondlicht',
    Member_2: 'Argus',
    Member_3: 'Kakania',
    Member_4: 'tooth-fairy',
    Niche_1: 'Raw DMG',
    Niche_2: '',
    Explanation:
      "A very standard setup, Mondlicht actually brings her own Reality DEF shred, so less use is seen for Isolde, while Argus can optimize Mondlicht's personal (especially her Ultimate) damage. Kakania and Tooth Fairy form a simple but effective defensive core."
  },
  {
    ID: 105,
    Name: 'Mondlicht Budget (I GUESS???)',
    Carry: 'Mondlicht',
    Member_2: 'Sonetto',
    Member_3: 'Necrologist',
    Member_4: 'tooth-fairy',
    Niche_1: 'Raw DMG',
    Niche_2: 'Budget',
    Explanation:
      'Less AP-friendly than the non-budget team due to Necrologist. Her [Prayer] application is important to keep Mondlicht from exploding due to her low stats. Meanwhile, Sonetto has access to reliable buffs and decent damage to provide support.'
  },
  {
    ID: 106,
    Name: 'Viable Poison Team (Serious)',
    Carry: 'Rabies',
    Member_2: 'Kakania',
    Member_3: 'Tuesday',
    Member_4: 'Sotheby',
    Niche_1: 'Poison',
    Niche_2: 'Crit',
    Explanation:
      'Actually a fairly solid team, Rabies inflicts [Poison] on all of his attacks. This combined with Tuesday and Sotheby results in a LOT of [Poison] being inflicted every round, while Kakania (+Sotheby) help keep the team alive.'
  },
  {
    ID: 107,
    Name: 'Scaring The Crows',
    Carry: 'Rabies',
    Member_2: 'Bkornblume',
    Member_3: 'Tuesday',
    Member_4: 'Yenisei',
    Niche_1: 'Poison',
    Niche_2: 'Budget',
    Explanation:
      'A similar idea to the non-budget team, Rabies and Tuesday can inflict a large amount of [Poison] to proc  and Bkornblume / Yenisei can do their part with their own debuffs. Meanwhile, Yenisei as our defensive anchor prevents high DMG from imploding Rabies.'
  },
  {
    ID: 108,
    Name: 'Reality DMG Core',
    Carry: 'Getian',
    Member_2: 'Isolde',
    Member_3: '',
    Member_4: '',
    Niche_1: 'Reality',
    Niche_2: 'Support Core',
    Explanation:
      'This is a core Support duo for Reality Damage Dealers, focused on shredding Reality DEF and boosting DMG Dealt at the same time. This core functions best in fights with multiple targets due to [Intermezzo] and [Admonition] and can proc Blasphemer of Night.'
  },
  {
    ID: 109,
    Name: 'Upgrade Core',
    Carry: 'Argus',
    Member_2: 'Mercuria',
    Member_3: '',
    Member_4: '',
    Niche_1: 'Upgrade',
    Niche_2: 'Support Core',
    Explanation:
      'This is a core Support duo for Upgrade or Crit Damage Dealers, focused on providing massive amounts of extra Crit DMG (+other effects) while either upgrading their own cards, applying [Adjustment] or facilitating each other. This core set-up allows Mercuria P0 to be used with any Damage Dealer.'
  },
  {
    ID: 110,
    Name: 'Defensive Core',
    Carry: '6',
    Member_2: 'Kakania',
    Member_3: '',
    Member_4: '',
    Niche_1: 'Defensive',
    Niche_2: 'Support Core',
    Explanation:
      'This is a core Support duo for general use. Both Kakania and 6 have primarily defensive aspects to their kits, with additional (not to be underestimated) offensive potential such as ATK increase, Upgrading and surprisingly high amounts of personal damage on both of the units.'
  },
  {
    ID: 111,
    Name: 'Healing Core',
    Carry: 'Kakania',
    Member_2: 'Vila',
    Member_3: '',
    Member_4: '',
    Niche_1: 'Healing',
    Niche_2: 'Support Core',
    Explanation:
      'This is a core Support duo for general use. Focused on healing; these two provide a solid defensive anchor behind any Carry + Support setup, additionally bringing things like a strong ATK buff, Crit buffs, Incantation buffs and Moxie gain.'
  },
  {
    ID: 112,
    Name: 'Burn Core',
    Carry: 'J',
    Member_2: 'Isolde',
    Member_3: '',
    Member_4: '',
    Niche_1: 'Burn',
    Niche_2: 'Support Core',
    Explanation:
      "This is a core Support duo for Burn or DoT teams. Both J and Isolde play incredibly well together, with J capitalizing off of Isolde's debuffs and Isolde enjoying J's high [Burn] application. This core provides a strong application of debuffs but may be risky due to Isolde being flimsy and J's self-damage."
  },
  {
    ID: 113,
    Name: 'Poison Core',
    Carry: 'Tuesday',
    Member_2: 'Sotheby',
    Member_3: '',
    Member_4: '',
    Niche_1: 'Poison',
    Niche_2: 'Support Core',
    Explanation:
      "This is a core 'Support' duo for Poison teams. Support is an optional title here, as the amount of [Poison] stacks triggering under Tuesday's [Fear's Cradle] results in high amounts of over-time damage triggering at the start of rounds. This is very strong offensively, assuming you can stick the stacks, and works especially well with BoN-oriented Carries."
  },
  {
    ID: 114,
    Name: 'Debuff Core',
    Carry: 'anjo-nala',
    Member_2: '6',
    Member_3: '',
    Member_4: '',
    Niche_1: 'Debuff',
    Niche_2: 'Support Core',
    Explanation:
      "General debuff Support combo utilizing both characters' [Collection of Debuff]s, 6's great Mass debuff application and Anjo Nala's damage increase based on debuffs + access to very consistent Crowd Control will help any struggling team lock down their opponents."
  },
  {
    ID: 115,
    Name: 'Shield Core',
    Carry: 'J',
    Member_2: 'ms-newbabel',
    Member_3: '',
    Member_4: '',
    Niche_1: 'Defensive',
    Niche_2: 'Support Core',
    Explanation:
      'Riposte core duo that applies massive shields and pumps out extra actions against high-AP enemies by capitalizing off of their attacks. Extremely good for teams that want a lot of FUAs or teams that want to prevent damage.'
  },
  {
    ID: 116,
    Name: 'HP Loss Core',
    Carry: 'Semmelweis',
    Member_2: 'Eternity',
    Member_3: '',
    Member_4: '',
    Niche_1: 'HP Loss',
    Niche_2: 'Support Core',
    Explanation:
      "With Eternity's Euphoria she and Semmelweis become the main HP Loss supportive duo, allowing allies to continuously sap their own HP while giving various Crit and DMG Bonus buffs scaling with missing health."
  },
  {
    ID: 117,
    Name: 'Inspiration Core',
    Carry: 'Barcarola',
    Member_2: 'Voyager',
    Member_3: '',
    Member_4: '',
    Niche_1: 'Inspiration',
    Niche_2: 'Support Core',
    Explanation:
      'A very solid all-round support core that both grants a team access to "Impromptu Incantation"s while providing solid offensive and defensive supporting capabilities (assuming Voyager has Euphoria).'
  }
];
