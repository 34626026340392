/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { IRevTeamInt } from '../../../common/model/graphql-types';
import { Reverse1999Character } from './rev-character';

interface IProps {
  team: IRevTeamInt;
}

export const REVTeamBox: React.FC<IProps> = ({ team }) => {
  return (
    <>
      <div className="box rev-team">
        <div className="team-header">
          <div className={`skill-icon`}>Team {team.ID}</div>
          <div className="skill-info">
            <p className="skill-name">{team.Name && team.Name}</p>
            <p className="skill-type">
              Niche 1: <strong>{team.Niche_1}</strong> || Niche 2:{' '}
              <strong>{team.Niche_2 ? team.Niche_2 : '-'}</strong>
            </p>
          </div>
        </div>
        <div className="team-details">
          <h6>Characters</h6>
          <div className="employee-container for-nikke">
            <Reverse1999Character
              mode="icon"
              slug={team.Carry.toLowerCase()}
              enablePopover
            />
            {team.Member_2 != '' && (
              <Reverse1999Character
                mode="icon"
                slug={team.Member_2.toLowerCase()}
                enablePopover
              />
            )}
            {team.Member_3 != '' && (
              <Reverse1999Character
                mode="icon"
                slug={team.Member_3.toLowerCase()}
                enablePopover
              />
            )}
            {team.Member_4 != '' && (
              <Reverse1999Character
                mode="icon"
                slug={team.Member_4.toLowerCase()}
                enablePopover
              />
            )}
          </div>
          <h6>About the team</h6>
          <p>{team.Explanation ? team.Explanation : '-'}</p>
        </div>
      </div>
    </>
  );
};
